import React, { useState } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  Progress,
  Select,
  useToast,
  Icon,
  Container,
  Heading,
} from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';
import axios, { AxiosError } from 'axios';

interface TranscriptionResponse {
  id: number;
  status: 'pending' | 'processing' | 'completed' | 'failed';
  message: string;
  file_size: number;
  estimated_time: string;
  task_id: string;
  model: string;
  language: string;
}

interface TranscriptionStatus {
  id: number;
  status: 'pending' | 'processing' | 'completed' | 'failed';
  text: string | null;
  error: string | null;
  file_size: number;
  original_filename: string;
  created_at: string;
  completed_at: string | null;
}

// Define the error response interface
interface APIError {
  detail: string;
  errors?: Array<{
    loc: string[];
    msg: string;
    type: string;
  }>;
}

const SUPPORTED_LANGUAGES = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  hi: 'Hindi',
  ja: 'Japanese',
  zh: 'Chinese'
};

const MODEL_SIZES = {
  tiny: 'Tiny (Fastest)',
  base: 'Base',
  small: 'Small',
  medium: 'Medium',
  large: 'Large (Most Accurate)'
};

const ALLOWED_FILE_TYPES = [
  'audio/mpeg', 'audio/wav', 'audio/mp4', 'audio/x-m4a',
  'video/mp4', 'video/mpeg', 'video/webm'
];

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB

const TranscriptionUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [transcriptionText, setTranscriptionText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [language, setLanguage] = useState('en');
  const [model, setModel] = useState('base');
  const toast = useToast();

  const handleError = (error: unknown) => {
    const axiosError = error as AxiosError<APIError>;
    const errorMessage = axiosError.response?.data?.detail 
      || axiosError.message 
      || 'An unknown error occurred';
    
    toast({
      title: 'Error',
      description: errorMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const validateFile = (file: File): string | null => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      return 'Invalid file type. Please upload an audio or video file.';
    }
    if (file.size > MAX_FILE_SIZE) {
      return 'File size exceeds 100MB limit.';
    }
    return null;
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const error = validateFile(file);
      
      if (error) {
        toast({
          title: 'Invalid file',
          description: error,
          status: 'error',
          duration: 5000,
        });
        return;
      }

      setSelectedFile(file);
      setTranscriptionText('');
      setUploadProgress(0);
    }
  };

  const checkTranscriptionStatus = async (transcriptionId: number): Promise<void> => {
    try {
      const response = await axios.get<TranscriptionStatus>(
        `${process.env.REACT_APP_API_URL}/transcription/${transcriptionId}`
      );
      
      if (response.data.status === 'completed') {
        setTranscriptionText(response.data.text || '');
        setIsProcessing(false);
        toast({
          title: 'Transcription completed',
          status: 'success',
          duration: 3000,
        });
      } else if (response.data.status === 'failed') {
        setIsProcessing(false);
        toast({
          title: 'Transcription failed',
          description: response.data.error || 'An unknown error occurred',
          status: 'error',
          duration: 5000,
        });
      } else {
        // Continue checking status every 2 seconds
        setTimeout(() => checkTranscriptionStatus(transcriptionId), 2000);
      }
    } catch (error) {
      setIsProcessing(false);
      handleError(error);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setIsProcessing(true);
    setUploadProgress(0);
    
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('language', language);
    formData.append('model_size', model);

    try {
      const response = await axios.post<TranscriptionResponse>(
        `${process.env.REACT_APP_API_URL}/transcription/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = progressEvent.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 0;
            setUploadProgress(progress);
          },
        }
      );

      toast({
        title: 'File uploaded successfully',
        description: `Estimated processing time: ${response.data.estimated_time}`,
        status: 'success',
        duration: 5000,
      });

      await checkTranscriptionStatus(response.data.id);

    } catch (error) {
      setIsProcessing(false);
      handleError(error);
    }
  };

  // Rest of your component JSX remains the same...

  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={8} align="stretch">
        <Heading textAlign="center" size="lg">
          Video to Text Transcription
        </Heading>

        <Box
          borderWidth={2}
          borderRadius="lg"
          p={10}
          borderStyle="dashed"
          borderColor="gray.300"
          bg="gray.50"
          _hover={{ bg: 'gray.100' }}
          cursor="pointer"
          onClick={() => document.getElementById('file-upload')?.click()}
        >
          <input
            id="file-upload"
            type="file"
            accept={ALLOWED_FILE_TYPES.join(',')}
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          <VStack spacing={4}>
            <Icon as={FiUploadCloud} w={12} h={12} color="gray.400" />
            <Text fontSize="lg" color="gray.500">
              Click or drag video/audio file to upload
            </Text>
            {selectedFile && (
              <Text color="blue.500" fontWeight="bold">
                Selected: {selectedFile.name}
              </Text>
            )}
          </VStack>
        </Box>

        <Select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          isDisabled={isProcessing}
        >
          {Object.entries(SUPPORTED_LANGUAGES).map(([code, name]) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </Select>

        <Select
          value={model}
          onChange={(e) => setModel(e.target.value)}
          isDisabled={isProcessing}
        >
          {Object.entries(MODEL_SIZES).map(([size, name]) => (
            <option key={size} value={size}>
              {name}
            </option>
          ))}
        </Select>

        <Button
          colorScheme="blue"
          size="lg"
          onClick={handleUpload}
          isLoading={isProcessing}
          loadingText="Processing..."
          isDisabled={!selectedFile || isProcessing}
        >
          Start Transcription
        </Button>

        {(uploadProgress > 0 || isProcessing) && !transcriptionText && (
          <Box>
            <Progress
              value={uploadProgress}
              size="md"
              colorScheme="blue"
              hasStripe
              isAnimated
            />
            <Text mt={2} textAlign="center">
              {uploadProgress === 100 && isProcessing
                ? 'Transcribing...'
                : `Uploading: ${uploadProgress}%`}
            </Text>
          </Box>
        )}

        {transcriptionText && (
          <Box
            p={6}
            borderWidth={1}
            borderRadius="lg"
            bg="white"
            shadow="sm"
          >
            <Text fontWeight="bold" mb={4}>
              Transcription Result:
            </Text>
            <Text whiteSpace="pre-wrap">{transcriptionText}</Text>
          </Box>
        )}
      </VStack>
    </Container>
  );
};

export default TranscriptionUpload;